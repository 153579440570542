// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.

// // Variables
//
// $primary: #ef604d;
// $primary-rgb: 239,96,77;
// $secondary: #599aa6;
// $secondary-rgb: 89,154,166;
// $success: #57d685;
// $success-rgb: 87,214,133;
// $warning: #ffae00;
// $warning-rgb: 255,174,0;
// $alert: #ec5840;
// $alert-rgb: 236,88,64;
//
// $proposals: #238ff7;
// $actions: #57d685;
// $debates: #fa6c96;
// $meetings: #fabc6c;
//
// $twitter: #55acee;
// $facebook: #3b5998;
// $google: #4285f4;
//
// // background colors used in public diff views
// $color-addition: #e6ffed;
// $color-removal: #ffeef0;
//
// // Define custom properties as css color variables
//
// :root{
//   --primary: #{$primary};
//   --primary-rgb: #{$primary-rgb};
//   --secondary: #{$secondary};
//   --secondary-rgb: #{$secondary-rgb};
//   --success: #{$success};
//   --success-rgb: #{$success-rgb};
//   --warning: #{$warning};
//   --warning-rgb: #{$warning-rgb};
//   --alert: #{$alert};
//   --alert-rgb: #{$alert-rgb};
//   --proposals: #{$proposals};
//   --actions: #{$actions};
//   --debates: #{$debates};
//   --meetings: #{$meetings};
//   --twitter: #{$twitter};
//   --facebook: #{$facebook};
//   --google: #{$google};
// }

.new-user-autocomplete {
  box-shadow: 0 0 5px #e8e8e8;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  border-radius: 4px;
  border: 1px solid darken(#e8e8e8, 20%);

  li {
    background-color: #e8e8e8;
    list-style: none;
    padding: .8rem .4rem;
    border-bottom: 1px solid darken(#e8e8e8, 20%);
    cursor: pointer;
    font-weight: 600;
  }
}

.ui-helper-hidden-accessible {
  display: none;
}

.is-bal-valid {
  border-color: $success !important;
  background-color: mix($success, $white, 10%) !important;
}

.is-bal-invalid {
  border-color: $input-error-color;
  @include form-input-error($input-error-color, 10%);
}
